<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Rendición de Cobranzas</h4>
            <div class="small text-muted">Administración de todas las rendiciones de cobranzas recibidas.</div>
          </b-col>

          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>                             
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="12">
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col> 
              <b-col sm="2">
                <v-select :options="arr.filter.sellers" v-model="filter.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>                        
              <b-col sm="2">
                <v-select :options="arr.filter.status" v-model="filter.status" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>    
              <b-col sm="sm">
                <v-select :options="arr.filter.read" v-model="filter.read" placeholder="Leido" :multiple="false" :select-on-tab="true"></v-select>                
              </b-col>
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>          
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="formartedItems"
                    :fields="table.fields"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    v-if="table.items.length">                       
                
                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col md="6">
                            <div class="product-line-titulo-subline mb-2">
                              <b>Detalle Rendición Nº:</b> {{row.item.id}}
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12">
                            <b-table class="mb-0"
                                    responsive="sm"
                                    head-variant="dark"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    :items="tableSub.items"
                                    :fields="tableSub.fields"                            
                                    v-if="tableSub.items.length">                                                                                     

                              <template v-slot:cell(id)="row">
                                <b>{{row.item.reports_payment.id}}</b>
                              </template>  

                              <template v-slot:cell(date)="row">
                                {{moment(row.item.reports_payment.date).format('DD MMMM YYYY')}}
                              </template>  

                              <template v-slot:cell(customers)="row">     
                                <div v-if="row.item.reports_payment.customers_id">             
                                  <b-avatar :src="row.item.reports_payment.customer.image"
                                            v-if="row.item.reports_payment.customer.image">
                                  </b-avatar>
                                  <b-avatar v-else 
                                            icon="building"                                            
                                            variant="dark">
                                  </b-avatar>     
                                  &nbsp;<b>{{row.item.reports_payment.customer.name}}</b>      
                                  <b-icon icon="circle-fill" v-if="!row.item.reports_payment.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
                                </div>
                              </template>

                              <template v-slot:cell(amount)="row">
                                $ {{row.item.reports_payment.amounts_items}}
                              </template>  

                              <template v-slot:cell(status)="row">
                                <div class="text-center">
                                  <b-badge v-if="row.item.status=='Enviado'" variant="info" class="report-payment-surrender-badge">
                                    {{row.item.status}}
                                  </b-badge>

                                  <b-badge v-if="row.item.status=='En Revisión'" variant="warning" class="report-payment-surrender-badge">
                                    {{row.item.status}}
                                  </b-badge>

                                  <b-badge v-if="row.item.status=='Aprobado'" variant="success" class="report-payment-surrender-badge">
                                    {{row.item.status}}
                                  </b-badge>
                                  
                                  <b-badge v-if="row.item.status=='Rechazado'" variant="danger" class="report-payment-surrender-badge">
                                    {{row.item.status}}
                                  </b-badge>

                                  <b-badge v-if="row.item.status=='No Recibido'" variant="secondary" class="report-payment-surrender-badge">
                                    {{row.item.status}}
                                  </b-badge>
                                </div>
                              </template>


                              <template v-slot:cell(observations)="data">
                                <div v-if="data.item.observations">
                                  <div v-if="data.item.observations.length >50" :title="data.item.observations">
                                    <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                                    {{data.item.observations.substr(0, 50)}}
                                  </div>
                                  <div v-else>                      
                                    {{data.item.observations}}
                                  </div>
                                </div>
                              </template>  
                              
                              <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                  <b-dropdown-item @click="seeDetail(data.item)">
                                    <i class="fa fa-list" style="color:green"></i> Ver Detalle
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="changeStatus(data.item)">
                                    <i class="fa fa-exchange" style="color:blue"></i> Cambiar Estado
                                  </b-dropdown-item>
                                </b-dropdown>
                              </template>

                            </b-table>
                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                          </b-col>
                        </b-row>

                      </b-col>
                    </b-row>
                  </b-card>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>  

                <template v-slot:cell(date)="data">
                  {{moment(data.item.date).format('DD MMMM YYYY')}}
                </template>      

                <template v-slot:cell(sellers_id)="data">
                  <div v-if="data.item.seller">                    
                    <b-avatar :src="data.item.seller.staff.image"
                              v-if="data.item.seller.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                      {{data.item.seller.staff.name}}
                    </b>  
                  </div>                    
                </template>

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>                      
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>       

                <template v-slot:cell(status)="data">
                  <div v-html="approvedItems(data.item.detail)" />
                </template>     

                <template v-slot:cell(read)="data">
                  <b-icon icon="toggle-on"  @click="checkRead(data.item)" class="h4 report-payment-surrender-check-read-table" v-if="!data.item.read" v-b-tooltip.hover title="Sin Leer"></b-icon>
                  <b-icon icon="toggle-off" class="h4" v-else v-b-tooltip.hover title="Leido"></b-icon>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="checkRead(data.item)" v-if="!data.item.read">
                      <b-icon icon="eye-fill"></b-icon> Marcar como leido
                    </b-dropdown-item>       
                    <b-dropdown-item @click="checkNotRead(data.item)" v-if="data.item.read">
                      <b-icon icon="eye-slash-fill"></b-icon> Marcar como no leido
                    </b-dropdown-item>  

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD ESTADOS -->
      <b-modal v-model="modal.formSub.active"              
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-form-group label="Estado">
              <b-form-select v-model="crud.formSub.status" :options="arr.select.status"></b-form-select>                    
            </b-form-group>
          </b-col>

          <b-col md="12">          
            <b-form-group label="Observaciones">
              <b-form-textarea                                
                v-model="crud.formSub.observations"
                size="sm"
                placeholder="Ingresar las observaciones"
              ></b-form-textarea>
            </b-form-group>                    
          </b-col>          
        </b-row>        

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>      

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import moment from 'moment'
  import Storage from '@/handler/storageSession'

  export default {     
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_COBRANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSurrender',
          elements: {}
        },         
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:'align-middle'},            
            {key: 'date', label: 'Fecha', class:'align-middle'},            
            {key: 'sellers_id', label: 'Vendedor', class:'align-middle'},
            {key: 'shipping', label: 'Método de Envío', class:'align-middle'},
            {key: 'observations', label: 'Observaciones', class:'align-middle'},
            {key: 'status', label: 'Estado', class: 'align-middle'},
            {key: 'read', label: 'Estado', class: "align-middle text-center"}, 
            {key: 'f_action', label:'', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'id', label: 'Nº', sortable: true, class:'align-middle'},
            {key: 'date', label: 'Fecha', class:'align-middle'},
            {key: 'customers', label: 'Cliente', class:'align-middle'},
            {key: 'amount', label: 'Importe', class:'align-middle'},
            {key: 'status', label: 'Estado', class:'text-center align-middle'},
            {key: 'observations', label: 'Observaciones', class:'align-middle'},
            {key: 'f_action', label:'', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },            
        crud: {       
          form: {
            id: 0,
            date: '',
            shipping: '',
            sellers_id: 0,
            observations: '',
            read: false,
          },                
          formSub: {
            id: 0,
            surrender_id: 0,
            reports_payment_id: 0,            
            status: '',
            observations: '',                        
          },             
        },
        modal: {
          formSub: {
            active: false,
            title: ''
          },          
        },        
        arr: {
          surrender: [],
          filter : {
            sellers: [],
            status: [
              {code:'Enviado', label: 'Enviado'},
              {code:'En Revisión', label: 'En Revisión'},
              {code:'Aprobado', label: 'Aprobado'},
              {code:'Rechazado', label: 'Rechazado'},              
              {code:'No Recibido', label: 'No Recibido'},              
            ],
            read: [
              {code:true, label: 'Leido'},
              {code:false, label: 'No Leidos'},                            
            ],            
          },          
          select: {
            status: [
              {value:'Enviado', text: 'Enviado'},
              {value:'En Revisión', text: 'En Revisión'},
              {value:'Aprobado', text: 'Aprobado'},
              {value:'Rechazado', text: 'Rechazado'},              
              {value:'No Recibido', text: 'No Recibido'},              
            ]
          }
        },
        filter: {
          date_start: '',
          date_end: '',
          sellers: [],          
          status: [],
          read: null,
        }        
      }
    },
    computed: {
      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          if(!item.read){
            item._rowVariant  = 'active'
          }
          return item
        })
      },
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }          
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      if(this.isSeller) {
        this.$router.push({ name: 'ReportsPaymentStaffCrudSurrenderSeller' })
      }

      this.filterLoad()
      this.filterReport()      
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.surrender.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterReport()
      },        
      load() {
        var result = serviceAPI.obtenerSurrender()

        result.then((response) => {
          var data = response.data
          this.table.items = data          
          this.arr.surrender = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].detail) {
              this.tableSub.items = this.table.items[this.table.rowSelected].detail
            }
          }           
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      remove(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.date = moment(item.date).format('DD MMMM YYYY')
        var seller = item.seller.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar la rendición Nº: (' + this.crud.formSub.id + ') con fecha ' + this.crud.formSub.date + ' emitida por el vendedor ' + seller + '?', {
          title: 'Borrar Registro',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSurrender(this.crud.formSub.id);

            result.then((response) => {             
              loader.hide()
              this.filterReport()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      approvedItems(item){        
        if(item) {
          var valid = true
          item.forEach(element => {            
            if(element.status != 'Aprobado') {
              valid = false
            }
          });
        }

        if(valid){
          return "<span class='reports-payment-surrender-status-ok'>Todos los recibos estan aprobados</span>";
        } else {
          return "<span class='reports-payment-surrender-status-fail'>Hay recibos sin aprobar</span>";
        }          
      },
      filterLoad() {        
        this.filterLoadSellers()           
        this.filter.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage() 
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.sellers = []
          data.forEach(element => {                            
            this.arr.filter.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      }, 
      filterReport(){
        let loader = this.$loading.show()   
   
        var result = serviceAPI.filtrarReportSurrender(this.filter)        
        result.then((response) => {
          loader.hide()

          var data = response.data
          this.table.items = data          
          this.arr.surrender = data
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].detail) {
              this.tableSub.items = this.table.items[this.table.rowSelected].detail
            }
          }           
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });          
      },              
      changeStatus(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.surrender_id = item.surrender_id
        this.crud.formSub.reports_payment_id = item.reports_payment_id
        this.crud.formSub.status = item.status
        this.crud.formSub.observations = item.observations

        this.modal.formSub.title = "Cambiar Estado"
        this.modal.formSub.active = true
      },      
      seeDetail(item) {     
        this.saveFilterStorage()   
        this.$router.push({ name: 'ReportsPaymentStaffDetailReport', params: {reportID: item.reports_payment_id} })
      },
      saveSub(){
        let loader = this.$loading.show();
        var result = serviceAPI.editarSurrenderDetail(this.crud.formSub);
        
        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.filterReport()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // SUBMENU
      checkRead(item) {
        let loader = this.$loading.show();     

        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.shipping = item.shipping
        this.crud.form.sellers_id = item.sellers_id                
        this.crud.form.observations = item.observations        
        this.crud.form.read = true
        
        var result = serviceAPI.editarSurrender(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Rendición marcada como Leida");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      checkNotRead(item) {
        let loader = this.$loading.show();     
        
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.shipping = item.shipping
        this.crud.form.sellers_id = item.sellers_id                
        this.crud.form.observations = item.observations  
        this.crud.form.read = false
        
        var result = serviceAPI.editarSurrender(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Rendición marcada como No Leida");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },      

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_report_payment_surrender', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_report_payment_surrender')) {
          this.filter = JSON.parse(Storage.getValue('filter_report_payment_surrender'))
        }         
      },
    }    
  }
</script>

<style>
  .report-payment-surrender-badge {
    font-size: 13px;
  }  
  .reports-payment-surrender-status-ok {
    color: darkgreen;
    font-size: 13px;
    font-weight: bold;
  }
  .reports-payment-surrender-status-fail {
    color: darkred;
    font-size: 13px;
    font-weight: bold;
  }
  .report-payment-surrender-check-read-table {
    cursor: pointer;
  }      
</style>